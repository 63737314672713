/* eslint-disable sonarjs/no-duplicate-string */

import { InternationalizationSchema } from '@app/internationalization/InternationalizationSchema';

export const schema: InternationalizationSchema = {
	language: 'American English (US)',
	helpers: {
		none: 'None',
		days: 'days',
		indefinitely: 'Indefinitely',
		finished: 'Finished',
		finish: 'Finish',
		complete: 'Complete',
		image: 'image',
		upload: 'Upload',
		load: 'Load',
		unload: 'Unload',
		unknown: 'Unknown',
		new: 'New',
		select: 'Select',
		'total-amount': 'Total Amount',
		info: 'Info',
	},
	enums: {
		'tax-policy': {
			'policy-excluded_tax': 'Tax Excluded',
			'policy-excluded_hst': 'HST Excluded',
			'policy-excluded_gst': 'GST Excluded',
			'policy-excluded_tax-default': 'Tax Excluded',

			'policy-included_tax': 'Tax Included',
			'policy-included_hst': 'HST Included',
			'policy-included_gst': 'GST Included',
			'policy-included_tax-default': 'Tax Included',
		},
		'invoice-tax-model': {
			'per-invoice_tax': 'Tax Per Invoice',
			'per-invoice_hst': 'HST Per Invoice',
			'per-invoice_gst': 'GST Per Invoice',
			'per-invoice_tax-default': 'Tax Per Invoice',

			'per-item_tax': 'Tax Per Item',
			'per-item_hst': 'HST Per Item',
			'per-item_gst': 'GST Per Item',
			'per-item_tax-default': 'Tax Per Item',
		},
		'crematory-type': {
			both: 'Both',
			cremation: 'Cremation',
			aquamation: 'Aquamation',
		},
		permissions: {
			'create-pet': 'Create Pet',
			'update-pet': 'Update Pet',
			'delete-pet': 'Delete Pet',
			'change-status': 'Change Status',
			'complete-service': 'Complete Service',
			'manage-invoices': 'Manage Invoices',
			'manage-users': 'Manage Users',
			quickbooks: 'Quickbooks',
		},
		'price-kind': {
			'base-price': 'Base Price',
			'special-service-price': 'Special Service Price',
			'delivery-price': 'Delivery Price',
			'urn-price': 'Urn Price',
			discount: 'Discount',
			'product-price': 'Product Price',
			engraving: 'Engraving',
		},
		'service-type': {
			private: 'Private',
			semiprivate: 'SemiPrivate',
			storage: 'Storage',
			communal: 'Communal',
			all: 'All',
			'products-only': 'Products Only',
			'aus-private': 'Individual',
			'aus-semiprivate': 'SemiIndividual',
		},
		'batch-type': {
			private: 'Private',
			semiprivate: 'SemiPrivate',
			communal: 'Communal',
			both: 'Both',
		},
		'price-type': {
			wholesale: 'Wholesale',
			retail: 'Retail',
			all: 'All',
		},
		'user-roles': {
			admin: 'Admin',
			'crematory-admin': 'Crematory admin',
			'crematory-user': 'Crematory user',
			clinic: 'Clinic',
		},
		'delivery-type': {
			'home-drop-off': 'Home Drop off',
			'vet-drop-off': 'Vet Drop off',
			clinic: 'Clinic',
			mail: 'Mail',
			'pick-up': 'Pick up',
			none: 'None',
		},
		'weight-units': {
			pounds: 'Pounds',
			kilograms: 'Kilograms',
		},
		'quickbooks-item-type': {
			inventory: 'Inventory',
			'non-inventory': 'Non Inventory',
			service: 'Service',
		},
		terms: {
			'due-on-receipt': 'Due On Receipt',
			'net-15': 'Net 15',
			'net-30': 'Net 30',
			custom: 'Custom',
		},
		'invoice-status': {
			open: 'Open',
			paid: 'Paid',
		},
		adjustment: {
			service: 'Service',
			product: 'Product',
		},
	},
	menu: {
		header: {
			clinics: 'Clinics',
			crematories: 'Crematories',
			'crematory-users': 'Crematory Users',
			dashboard: 'Dashboard',
			'discount-codes': 'Discount Codes',
			pets: 'Pets',
			quickbooks: 'Quickbooks',
		},
		submenu: {
			pets: 'Pets',
			batches: 'Batches',
			'coordinate-batches': 'Coordinate Batches',
			labels: 'Labels',
			customers: 'Customers',
			'quickbooks-items': 'Quickbooks Items',
			history: 'History',
			'api-import': 'API Import',
		},
	},
	'page-headers': {
		'api-keys': 'API Keys',
		batches: 'Batches',
		'additional-notes': 'Additional notes',
		invoices: 'Invoices',
		adjustments: 'Adjustments',
		'store-entries': 'Inventory Items',
		prices: 'Prices',

		'item-names': {
			'api-key': 'API Key',
			batch: 'Batch',
			invoice: 'Invoice',
			adjustment: 'Adjustment',
			'store-entry': 'Inventory Item',
			price: 'Price',
		},
	},
	lists: {
		'clinic-pet-list': {
			cards: {
				'pet-card': {
					'owner-name': 'Owner\'s name',
					'date-entered': 'Date entered',
					engraving: 'Engraving',
					color: 'Color',
					'reported-weight': 'Reported weight',
					gender: 'Gender',
				},
			},
			columns: {
				name: 'Name',
				species: 'Species',
				breed: 'Breed',
				gender: 'Gender',
				color: 'Color',
				weight: 'Weight',
				'service-type': 'Service type',
				status: 'Status',
				'created-date': 'Created date',
				warnings: {
					'unknown-status': 'Unknown status',
				},
			},
		},
	},
	editors: {
		'profile-editor': {
			title: 'Profile Editor',
			'base-info': {
				title: 'General Info',
				'first-name': 'First Name',
				'last-name': 'Last Name',
				email: 'Email',
			},
			'localization-preferences': {
				title: 'Localization Preferences',
				language: 'Language',
				'date-format': 'Date Format',
				'time-format': 'Time Format',
			},
			messages: {
				'update-success': 'Successfully updated profile!',
			},
			permissions: {
				'create-pet': 'The user can create pet',
				'update-pet': 'The user can update pet',
				'delete-pet': 'The user can delete pet',
				'change-status': 'The user can change status',
				'complete-service': 'The user can complete service',
				'manage-invoices': 'The user can manage invoices',
				'manage-users': 'The user can manage users',
				quickbooks: 'The user can manage Quickbooks',
			},
		},
		'pet-editor': {
			cards: {
				'pet-info': {
					specie: 'Specie',
					breed: 'Breed',
					name: 'Name',
					engraving: 'Engraving',
					color: 'Colour',
					gender: 'Gender',
					'reported-weight': 'Reported weight',
					'actual-weight': 'Actual weight',
				},
			},
			labels: {
				invoice: 'Invoice',
				crematory: 'Crematory',
				'price-type': 'Pricing Type',
				rush: 'Rush',
				'rush-details': 'This pet needs urgent service',
				'on-hold': 'On Hold',
				'on-hold-details': 'This pet is on hold',

				'owner-first-name': 'First name',
				'owner-last-name': 'Last name',
				'owner-email': 'Email',
				'owner-phone-1': 'Phone number',
				'owner-phone-2': 'Phone number',

				'owner-street-address-1': 'Street address 1',
				'owner-street-address-2': 'Street address 2',
				'owner-zip': 'Zip',
				'owner-postal': 'Postal',
				'owner-mail-default': 'Mail index',
				'owner-city': 'City',
				'owner-state': 'State',
				'owner-province': 'Province',
				'owner-region-default': 'Region',

				'notes-about-family': 'Notes about Family',
				notes: 'Notes',
				discount: 'Discount',
				'pick-up-service': 'Pickup Service',

				'date-received': 'Date Received',
				clinic: 'Clinic',
				'clinic-location': 'Clinic Location',
				vet: 'Vet',
				'clinic-internal-number': 'Internal ID',

				'pet-species': 'Species',
				'pet-breed': 'Breed',
				'is-mixed': 'Is mixed',
				'pet-name': 'Pet Name',
				'pet-color': 'Color',
				'pet-gender': 'Gender',
				'pet-reported-weight': 'Reported Weight',
				'pet-actual-weight': 'Actual Weight',
				'pet-ashes-weight': 'Ashes Weight',

				'delivery-type': 'Delivery type',
				'delivery-address-1': 'Delivery address',
				'delivery-address-2': 'Delivery address 2',
				'delivery-zip': 'Delivery zip',
				'delivery-postal': 'Delivery postal',
				'delivery-mail-default': 'Delivery mail index',
				'delivery-city': 'Delivery city',
				'delivery-state': 'Delivery state',
				'delivery-region-default': 'Delivery region',
				'delivery-province': 'Delivery province',
				'delivery-clinic': 'Delivery clinic',

				'special-instructions': 'Special instructions',
				carrier: 'Carrier',
				'tracking-number': 'Tracking number',

				'spread-long': 'Longitude',
				'spread-lat': 'Latitude',

				'type-of-service': 'Type of Service',
				engraving: 'Engraving',

				'urn-type': 'Urn Type',
				'urn-size': 'Urn Size',
				'urn-count': 'Count',

				'product-count': 'Count',
			},

			sections: {
				owner: 'Owner Information',
				pet: 'Pet Information',
				'special-services': 'Special Services',
				delivery: 'Delivery',
				clinic: 'Clinic Information',
				process: 'Process Information',
				products: 'Products',
				documents: 'Documents',
			},

			actions: {
				'edit-pet': 'Edit pet',
				'add-pet': 'Add Pet',
				'add-batch': 'Add Batch',

				'save-action': 'Save',
				'cancel-action': 'Cancel',

				export: 'Export',
				'export-title': 'Export to excel document',
			},

			'price-tag': {
				total: 'Total',
				subtotal: 'Subtotal',
				free: 'Free',
				price: 'Price',
				'price-not-found': 'Price for entered data isn\'t set',
				discount: 'Discount',
				'clinic-discount': 'Clinic Discount',
				tax: 'Tax',
				hst: 'HST',
				gst: 'GST',
				'tax-default': 'Tax',
			},

			'pet-tag': {
				'button-text': 'Print pet tag',
				'button-invoice-text': 'Print Receipt',
			},
		},
		'clinic-pet-editor': {
			cards: {
				'pet-info': {
					name: 'Name',
					engraving: 'Engraving',
					gender: 'Gender',
					weight: 'Weight',
					species: 'Species',
					breed: 'Breed',
					'breed-type': 'Breed type',
					color: 'Color',
					'urn-type': 'Urn Type',
					'urn-count': 'Quantity',
				},
				'owner-info': {
					'owner-type': 'Owner type',
					'owner-first-name': 'Owner first name',
					'owner-last-name': 'Owner last name',
					'owner-email': 'Owner email',
					'owner-phone': 'Owner phone',
				},
				'cremation-info': {
					crematory: 'Crematory',
					'cremation-type': 'Cremation type',
					'clinic-location': 'Clinic location',
				},
			},
		},
		'crematory-editor': {
			headers: {
				'general-preferences': 'General preferences',
				'email-notifications': 'Email notifications',
				localizations: 'Localization preferences',
				'special-service-colors': 'Special service colors',
				delivery: 'Delivery',
				pricing: 'Pricing',
				'expiration-colors': 'Expiration colors',
				'change-status-policy': 'Change status policy',
			},
			information: {
				address: {
					'street-address-1': 'Street address 1',
					'street-address-2': 'Street address 2',
					zip: 'Zip',
					postal: 'Postal',
					'mail-default': 'Postal code',
					city: 'City',
					state: 'State',
					province: 'Province',
					'region-default': 'Region',
				},
			},
			preferences: {
				pricing: {
					tax: 'Tax',
					hst: 'HST',
					gst: 'GST',
					'tax-default': 'Tax',
					'show-prices-to-clinic': 'Show prices to clinic',

					captions: {
						'show-prices-to-clinic': 'The clinics will display prices in the pet editor',
					},
				},
				general: {
					'tag-icon': 'Tag Icon',
					'weight-unit': 'Weight Units',
					'use-service-type': 'Use Service Type',
					'prefill-engraving': 'Prefill Engraving',
					'show-internal-id': 'Show Internal Id',
					'require-internal-id': 'Require Internal Id',
					'upload-files-to-clinic': 'Upload files to Clinic',
					'crematory-type': 'Crematory Type',
					'manage-stock': 'Manage Stock',
					'retail-requires-urn': 'Require urn for Retail price',
					'wholesale-requires-urn': 'Require urn for Wholesale price',

					captions: {
						'use-service-type': 'Use this Service Type for crematory',
						'prefill-engraving': 'Add engraving line filled with pet\'s name automatically',
						'show-internal-id': 'Display Internal ID for this Service Type',
						'require-internal-id': 'The Internal ID will be required',
						'upload-files-to-clinic': 'Clinics will be able to upload files for pets',
						'manage-stock': 'Keep track of items in stock',
						'retail-requires-urn': 'It is required to have an urn for the type of Retail price and this type of service',
						'wholesale-requires-urn': 'It is required to have an urn for the type of Wholesale price and this type of service',
					},
				},
			},
		},
		'clinic-editor': {
			labels: {
				name: 'Name',
				'contact-first-name': 'Contact First Name',
				'contact-last-name': 'Contact Last Name',
				email: 'Email',
				phone: 'Phone number',

				'street-address-1': 'Street Address 1',
				'street-address-2': 'Street Address 2',
				zip: 'Zip',
				postal: 'Postal',
				'mail-default': 'Postal code',
				city: 'City',
				state: 'State',
				province: 'Province',
				'region-default': 'Region',

				country: 'Country',
				language: 'Language',
				'date-format': 'Date Format',
				'time-format': 'Time Format',
				'clinic-type': 'Clinic Type',
			},
			sections: {
				'general-info': 'General Information',
				address: 'Address',
				'localization-info': 'Localization Info',
				'linked-crematories': 'Linked Crematories',
				notes: 'Notes',
				delivery: 'Delivery',
				danger: 'Danger',
			},
		},
		'expiration-coloring-editor': {
			validation: {
				'days-passed': {
					integer: '"Days" should be an integer',
					'non-negative': '"Days" shouldn\'t be negative',
					required: '"Days" is a required field',
					'type-error': '"Days" should be a number',
				},
				'color-error': '"Color" is a required string',
			},
			modal: {
				title: 'Here comes the title',
				days: 'Days',
				color: 'Color',
			},
			table: {
				title: 'Expiration colors',
				item: 'expiration color',
				columns: {
					color: 'Color',
					'days-passed': 'Days passed',
				},
			},
		},
		'batch-editor': {
			'batch-info': 'Batch Info',
			'missing-info': 'Missing Batch Info',
			'pets-list': 'Pets List',
			'batch-image': 'Batch Image',
			'force-finish': 'Force Finish',
			'number-of-pet': 'Number of pets',
			'loaded-by': 'Loaded by',
			'loaded-at': 'Loaded at',
			'finished-by': 'Finished by',
			'finished-at': 'Finished at',
			'with-note': 'With note',
		},
		'invoice-editor': {
			'confirm-message': 'Price of some pets had been changed since invoice was generated. Consider reissuing invoice.',
			'adjustment-attach-message': 'Adjustment has been attached to the invoice.',
			'adjustment-change-message': 'Adjustment has been successfully changed.',
			'successfully-saved-message': 'Successfully saved.',
			'select-clinic-to-add-pets': 'Select clinic to add pets',
			'attach-pets-to-invoice': 'Click here to attach pets to invoice',
			'include-on-hold': 'Include On Hold',
			'by-dates': 'By dates',
			manually: 'Manually',
			'create-invoice': 'Create invoice',
			'save-changes': 'Save changes',
			'send-email': 'Send email to clinic',
			'delete-invoice': 'Delete Invoice',
			'print-detailed-invoice': 'Print Detailed Invoice',
			'exclude-free-services': 'Exclude Free Services',
			'per-location': 'Per Location',
			'clinic-address': 'Clinic address',

			'send-to-quickbooks': 'Send To Quickbooks',
			'quickbooks-id': 'Quickbooks Id',
			'quickbooks-document-number': 'Quickbooks Document Number',
			'sent-by': 'Sent By',
			'sent-at': 'Sent At',
		},
		'dashboard-editor': {
			entities: {
				statuses: 'Statuses',
				services: 'Services',
				'special-services': 'Special Services',
				reports: 'Reports',
			},
			'item-entities': {
				status: 'Status',
				service: 'Service',
				report: 'Report',
			},
			texts: {
				'empty-view': 'No Status Selected. Edit Dashboard to add statuses to display.',
				'empty-edit': 'You don\'t have any',
			},
		},
	},
	modals: {
		'pet-info-modal': {
			labels: {
				color: 'Color',
			},
		},

		'batch-modal': {
			'batch-action': 'Batch Action',
			'select-machine': 'Select Machine',
			'select-pets': 'Select Pets',
			'confirm-batch': 'Confirm Batch',

			'leave-batch': 'Leave the batch',
			'confirm-text': 'It will not be saved',
			'batch-overview': 'Batch overview',
			chemicals: 'Chemicals',
			'no-overview': 'There is no overview data',
			'no-chemicals': 'There are no chemicals',
			'batch-type': 'Batch Type',
			'pets-amount': 'Pets Amount',
			'total-weight': 'Total Weight',
		},
	},
	selects: {
		'color-select': {
			placeholder: 'Color',
		},
		'region-select': {
			placeholder: {
				state: 'Please, pick a state',
				province: 'Please, pick a province',
				'region-default': 'Please, pick a region',
			},
		},
		'postal-code-autocomplete': {
			placeholder: {
				zip: 'Zip',
				postal: 'Postal',
				'mail-default': 'Postal code',
			},
		},
	},
	buttons: {
		'export-pet-report': {
			values: {
				id: 'Id',
				name: 'Name',
				species: 'Species',
				breed: 'Breed',
				'actual-weight': 'Actual weight',
				'reported-weight': 'Reported weight',
				'price-type': 'Price type',
				'service-type': 'Service type',
				'delivery-type': 'Delivery type',
				clinic: 'Clinic',
				crematory: 'Crematory',
				'entered-date': 'Entered date',
				status: 'Status',
				city: 'City',
				state: 'State',
				province: 'Province',
				'mail-default': 'Mail index',
				zip: 'Zip',
				postal: 'Postal',
				'region-default': 'Region',
				'street-name-1': 'Street name 1',
				'street-name-2': 'Street name 2',
				notes: 'Notes',
				gender: 'Gender',
				color: 'Color',
				phone: 'Phone',
				email: 'Email',
				engraving: 'Engraving',
				'first-name': 'First Name',
				'last-name': 'Last Name',
				'base-price-total': 'Total base price',
				'product-price-total': 'Total product price',
				'delivery-price-total': 'Total delivery price',
				'special-service-total': 'Total special service price',
				'pickup-service': 'Pickup service price',
				'current-status-date': 'Date that it became the current status',
				service: 'Service',
				product: 'Product',
				tax: 'Tax',
				hst: 'HST',
				gst: 'GST',
			},
			state: {
				title: 'State',
				'up-to-date': 'Up-to-date',
				pending: 'Pending',
			},
		},
		'export-clinic-invoice-report': {
			id: 'Id',
			'invoice-number': 'Invoice Number',
			'clinic-name': 'Clinic Name',
			'pets-count': 'Pets Count',
			total: 'Total',
			status: 'Status',
			'issued-date': 'Issued Date',
			'due-date': 'Due Date',

			'base-price-total': 'Total base price',
			'product-price-total': 'Total product price',
			'urn-price-total': 'Total urn price',
			'delivery-price-total': 'Total delivery price',
			'special-service-total': 'Total special service price',
			'pickup-fees-total': 'Total pickup fees',
			'adjustment-price-total': 'Total adjustment price',
			service: 'Service',
			product: 'Product',
			tax: 'Tax',
			hst: 'HST',
			gst: 'GST',
		},
		add: 'Add',
		edit: 'Edit',
		save: 'Save',
		cancel: 'Cancel',
		leave: 'Leave',
		stay: 'Stay',
		confirm: 'Confirm',
		'open-menu': 'Open Menu',
		'download-pdf': 'Download PDF',
		'load-more': 'Load more',
	},
	colors: {
		green: 'green',
		yellow: 'yellow',
		orange: 'orange',
		red: 'red',
		violet: 'violet',
		blue: 'blue',
		lightblue: 'light blue',
		lightgreen: 'light green',
		pink: 'pink',
		black: 'black',
		transparent: 'transparent',
	},
	filters: {
		search: 'Search',
		'search-by-name': 'Search by name',
		reset: 'Reset filters',
		quantity: 'item(s)',

		id: 'Id',
		query: 'Query',
		name: 'Name',
		'owner-first-name': 'Owner First Name',
		'owner-last-name': 'Owner Last Name',
		crematories: 'Crematories',
		'contact-person': 'Contact Person',
		'pet-specie': 'Species',
		'pet-source': 'Source',
		'pet-breed': 'Breed',
		clinic: 'Clinic',
		'clinic-type': 'Clinic Type',
		'invoice-number': 'Invoice Number',
		role: 'Role',
		'crematory-role': 'Role',
		email: 'Email',
		phone: 'Phone',
		'service-type': 'Service Type',
		'service-type-with-all': 'Service Type',
		status: 'Status',
		'excluding-status': 'Excluding Status',
		date: 'Date',
		'date-to': 'To',
		'date-from': 'From',
		'delivery-type': 'Delivery Type',
		'price-type': 'Price Type',
		'price-type-with-all': 'Price Type',
		'special-services': 'Special Services',
		enabled: 'Status',
		'invoice-status': 'Invoice Status',
		'order-number': 'Purchase Order Number',
		'due-date': 'Due Date',
		'has-issues': 'Issues',
		rush: 'Rush',
		'on-hold': 'On Hold',
		'is-special': 'Special',
		'my-pets': 'My Pets',
		user: 'User',
		'internal-id': 'Internal Id',
		'status-function': 'Status Function',
		category: 'Category',
		'is-customer': 'Is Customer',
		route: 'Route',
		machine: 'Machine',
		type: 'Type',
		'price-kind': 'Price Kind',
		'is-linked-to-quickbooks': 'Linked to Quickbooks',
		'stock-quantity': 'Quantity',
		confirmed: 'Confirmed',
		products: 'Products',
		urns: 'Urns',
	},
	table: {
		columns: {
			actions: 'Actions',

			name: 'Name',
			item: 'Item',
			discount: 'Discount',
			'pet-name': 'Pet Name',
			'owner-name': 'Owner Name',
			species: 'Pet Species',
			breed: 'Pet Breed',
			gender: 'Gender',
			'pet-color': 'Color',
			weight: 'Weight (Actual / Reported)',
			'weight-short': 'Weight (A/R)',
			'actual-weight': 'Actual Weight',
			'report-weight': 'Reported Weight',
			'service-type': 'Service Type',
			'price-type': 'Price Type',
			'price-kind': 'Price Kind',
			status: 'Status',
			'delivery-type': 'Delivery Type',
			clinic: 'Clinic',
			crematories: 'Crematories',
			'entered-date': 'Entered Date',
			'created-date': 'Created Date',
			'internal-id': 'Internal Id',

			expiration: 'Expiration',
			machine: 'Machine',
			'machine-position': 'Machine position',
			type: 'Type',
			note: 'Note',
			description: 'Description',

			date: 'Date',
			'due-date': 'Due Date',
			number: 'Number',
			terms: 'Terms',
			total: 'Total',
			subtotal: 'Subtotal',
			value: 'Value',
			price: 'Price',

			'number-of-prices': 'Number of Prices',
			shipping: 'Shipping',
			pickup: 'Pick Up',
			products: 'Products',
			urns: 'Urns',
			'special-services': 'Special Services',
			tax: 'Tax',
			hst: 'HST',
			gst: 'GST',
			discounts: 'Discounts',
			attributes: 'Attributes',
			'clinic-can-pick': 'Clinic Can Pick',
			'total-urns': 'Total Urns',

			'invoice-model_tax': 'Invoice Tax Model',
			'invoice-model_hts': 'Invoice HST Model',
			'invoice-model_gst': 'Invoice GST Model',
			'invoice-model_tax-default': 'Invoice Tax Model',
		},
		actions: {
			edit: 'Edit',
			print: 'Print tag',
			delete: 'Delete',
			force: 'Force',
		},
	},
	control: {
		next: 'Next',
		previous: 'Previous',
		back: 'Back',
	},
	text: {
		'pet-warning-issues': 'have been imported with issues and require review.',
		'api-key-success-message': `Please, save this API Key into secure storage. 
		This API Key is used by external apps to log in into wingstracker. 
		API Key is not stored in Wingstracker.`,
		'api-key-success-title': 'Your key has been generated.',
		'batch-status-loaded': 'Pets are loaded',
		'batch-overriden-message': 'Some chemicals are overriden. Make sure you don\'t use too much / too little.',
		'upload-image-message': 'Image size must not exceed 4 Mb',
		'add-pet': 'Add Pet',
	},
	messages: {
		type: {
			success: 'Success',
			error: 'Error',
		},

		text: {
			delete: 'was deleted',
			finished: 'is finished',
			confirm: 'Confirm changes reviewed',
			update: 'has been updated',
			copy: 'was copied',
		},
	},
	unknown: {
		key: 'Unknown',

		items: {
			user: 'User',
			machine: 'Machine',
		},
	},
	reports: {
		'income-reports': 'Income Reports',
		'average-turn-around-report': 'Average Turn Around Report',
	},
};
