import * as React from 'react';
import { useSelector } from 'react-redux';

import {
	FieldHelperProps,
	FieldInputProps,
	useField,
	useFormikContext,
} from 'formik';
import { v4 } from 'uuid';

import { Nullable } from '@common/typescript/objects/Nullable';

import { PetPrice, ServiceType } from '@app/objects/Pet';

import {
	SpecialServiceActions,
	SpecialServiceList,
} from '@app/components/Pages/PetEditor/OldPetEditor/Components/Sections/SpecialServiceSection/SpecialServiceList';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import {
	PriceType,
	Price,
	PriceKind,
} from '@app/objects/Price';
import { useRequest } from '@app/hooks/useRequest';
import { ApplicationState } from '@app/store';
import { SectionContainer } from '@app/components/Pages/PetEditor/OldPetEditor/Components/Containers/SectionContainer';

interface SpecialServiceSectionProps {
	prices: Array<Price>;
}

function getActions(field: FieldInputProps<Array<PetPrice>>, form: FieldHelperProps<Array<PetPrice>>): SpecialServiceActions {
	function setValue(value: Array<PetPrice>) {
		form.setValue(value, false);
	}

	return {
		add: (items: Array<PetPrice>) => {
			const values = [...field.value, ...items];

			setValue(values);
		},
		update: (clientId: string, value: Partial<PetPrice>) => {
			const values = field.value.map((q: PetPrice) => {
				if (q.clientId === clientId) {
					return {
						...q,
						...value,
					};
				}

				return q;
			});

			setValue(values);
		},
		delete: (clientId: string) => {
			const values = field.value.map((q: PetPrice) => {
				if (q.clientId === clientId && !(q.done || q.completedCount > 0)) {
					return {
						...q,
						removed: true,
					};
				}

				return q;
			}).filter((q: PetPrice) => !(q.removed && q.id < 0));

			setValue(values);
		},
	};
}

interface PetData {
	crematoryId: number;
	priceType: PriceType;
	serviceType: ServiceType;
	clinicId?: Nullable<number>;
}

interface ServiceProps {
	pet: PetData;
	pendingOnly: boolean;
	defaultOnly: boolean;
}

interface ProviderProps {
	onLoad: (value: boolean) => void;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const DefaultServicesProvider: React.VFC<ProviderProps> = ({ onLoad }: ProviderProps) => {
	const { values, setFieldValue, initialValues } = useFormikContext<PetFormValues>();
	const activeCrematoryId = useSelector((state: ApplicationState) => state.login.user?.activeCrematoryId);
	const noActiveCrematory = values.id > 0 ? initialValues.crematoryId : -1;
	const isActiveCrematory = !activeCrematoryId ? noActiveCrematory : activeCrematoryId;
	const availableServices = useRequest<Array<Price>, ServiceProps>('availableSpecialServiceList', undefined, { requestOnMount: false });
	const prevCrematoryId = React.useRef<number>(isActiveCrematory);
	const prevClinicId = React.useRef<Nullable<number> | undefined>(values.clinicId);
	const prevServiceType = React.useRef<Nullable<ServiceType>>(values.id > 0 ? initialValues.serviceType : null);
	const prevPriceType = React.useRef<Nullable<PriceType>>(values.id > 0 ? initialValues.priceType : null);
	const equal = values.crematoryId !== prevCrematoryId.current
		|| values.clinicId !== prevClinicId.current
		|| values.serviceType !== prevServiceType.current
		|| values.priceType !== prevPriceType.current;
	const changedOnlyClinicRef = React.useRef<boolean>(false);

	React.useEffect(() => {
		const cur = {
			serviceType: values.serviceType,
			priceType: values.priceType,
			crematoryId: values.crematoryId,
			clinicId: values.clinicId,
		};
		changedOnlyClinicRef.current = values.clinicId !== prevClinicId.current
		&& values.crematoryId === prevCrematoryId.current
		&& values.serviceType === prevServiceType.current
		&& values.priceType === prevPriceType.current;

		if (equal) {
			prevCrematoryId.current = values.crematoryId;
			prevServiceType.current = values.serviceType;
			prevPriceType.current = values.priceType;
			prevClinicId.current = values.clinicId;

			availableServices.reload({
				pet: cur,
				pendingOnly: values.id > 0,
				defaultOnly: true,
			});
		}
	}, [values.crematoryId, values.clinicId, values.serviceType, values.priceType]);

	React.useEffect(() => {
		if (availableServices.item !== null) {
			const services = availableServices.item.map((item: Price) => {
				const count = item.batchCount || 1;
				const extra = Math.max(count - item.batchCount, 0) * item.value;

				return {
					id: -1,
					clientId: v4(),
					deleted: false,

					priceId: item.id,
					price: item,

					pet: null,
					petId: values.id,

					count,
					completedCount: 0,
					done: false,

					value: item.batchPrice + extra,
					extra,

					name: item.specialService?.name ?? 'Special Service',
					editor: null,
					editorId: null,
				};
			});
			const old = (values.services ?? []).filter((s: PetPrice) => s.id > 0).map((q: PetPrice) => {
				if (changedOnlyClinicRef.current && q.price?.priceKind === PriceKind.Discount) return q;

				return { ...q, removed: true };
			});

			setFieldValue('services', [...services, ...old], false);
		}
	}, [availableServices.item]);
	React.useEffect(() => onLoad(availableServices.loading), [availableServices.loading]);

	return null;
};

export const SpecialServiceSection: React.FC<SpecialServiceSectionProps> = ({ prices }: SpecialServiceSectionProps) => {
	const [loading, setLoading] = React.useState<boolean>(false);
	const [field, , form] = useField<Array<PetPrice>>('services');
	const actions: SpecialServiceActions = getActions(field, form);

	return (
		<SectionContainer titleLocalizationKey="sections.special-services">
			<DefaultServicesProvider onLoad={setLoading} />
			<SpecialServiceList
				actions={actions}
				loading={loading}
				prices={prices}
			/>
		</SectionContainer>
	);
};
