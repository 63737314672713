import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Field, FieldProps, useFormikContext } from 'formik';

import Select from 'antd/lib/select';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Col, Row } from 'antd/lib/grid';
import Space from 'antd/lib/space';

import { FormikInput } from '@common/react/components/Forms/FormikInput/FormikInput';

import { PriceType } from '@app/objects/Price';
import { PetFormValues } from '@app/components/Pages/PetEditor/OldPetEditor/Types';
import { iKey } from '@app/components/Pages/PetEditor/OldPetEditor/Services';
import { UserRole } from '@app/objects/User';
import { CrematorySelect } from '@app/components/UI/Inputs/CrematorySelect';
import { CrematoryListInfo, getServiceTypePreferenceKind } from '@app/objects/Crematory';
import { PetOnHold, ServiceType } from '@app/objects/Pet';
import { isPickDefault } from '@app/store/SelectList/SelectsInterfaces';
import { ApplicationState } from '@app/store';
import { LocalSelect, Option } from '@app/components/UI/Inputs/LocalSelect';
import { serviceTypes } from '@app/components/UI/Inputs/LocalSelectOptions';
import { ControlLabel } from '@app/components/UI/ControlLabel/ControlLabel';
import { useCrematory } from '@app/hooks/useCrematory';
import { useCountry } from '@app/hooks/useCountry';
import { australiaCode } from '@app/components/UI/Inputs/PhoneInput';

export const TopSection: React.FC = () => {
	const { t } = useTranslation();
	const { values } = useFormikContext<PetFormValues>();
	const user = useSelector((state: ApplicationState) => state.login.user);
	const crematory = useCrematory(values.crematoryId);
	const clinics = useSelector((state: ApplicationState) => state.clinics.items);
	const clinic = React.useMemo(() => clinics.find((c) => c.id === values.clinicId), [values.clinicId, clinics]);
	const params = React.useMemo(
		() => (values.clinicId ? { id: clinic?.countryId } : { code: crematory?.country }),
		[clinic, values.clinicId, crematory],
	);
	const country = useCountry(params.code, params.id);
	const isAustralia = React.useMemo(() => country?.phoneCode === australiaCode, [country]);
	const [serviceTypeOptions, setServiceTypeOptions] = React.useState<Array<Option>>([]);

	React.useEffect(() => {
		if (!crematory || !values.crematoryId) return;

		setServiceTypeOptions(serviceTypes(t, isAustralia, crematory));
	}, [crematory, values.crematoryId, isAustralia]);

	return (
		<Space style={{ display: 'flex' }} direction="vertical">
			<Row className="is-relative" gutter={[30, 8]}>
				<Col xs={24} sm={24} md={8}>
					<Field name="crematoryId">
						{(fieldProps: FieldProps<PetFormValues>) => {
							if (user?.role === UserRole.Crematory) return null;
							if (user?.role === UserRole.CrematoryUser) return null;

							return (
								<FormikInput
									fieldProps={fieldProps}
									title={`${t(iKey('labels.crematory'))} *`}
									containerClassName=""
									render={({ field, form }: FieldProps<number, PetFormValues>) => (
										<CrematorySelect
											value={field.value}
											onChange={(value?: number) => {
												form.setFieldValue(field.name as keyof PetFormValues, value, false);
												form.setFieldValue('clinicId', null);
												form.setFieldValue('clinicName', '');
											}}
											pickDefault={(store: Array<CrematoryListInfo>) => isPickDefault(store)}
											placeholder="Crematory"
										/>
									)}
								/>
							);
						}}
					</Field>
				</Col>
			</Row>
			<Row gutter={[30, 8]}>
				<Col xs={24} sm={24} md={8}>
					<Field name="serviceType">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								containerClassName=""
								title={`${t(iKey('labels.type-of-service'))} *`}
								render={({ field, form }: FieldProps<ServiceType, PetFormValues>) => (
									<LocalSelect
										onChange={(value: ServiceType) => form.setFieldValue(field.name as keyof PetFormValues, value, false)}
										value={field.value}
										options={serviceTypeOptions}
										fieldName={field.name}
										deselectType={field.name}
										filterName={field.name}
										allowClear={false}
										placeholder="Select the Service Type"
									/>
								)}
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={8}>
					<Field name="priceType">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={`${t(iKey('labels.price-type'))} *`}
								containerClassName=""
								render={({ field, form }: FieldProps<PriceType, PetFormValues>) => (
									<Select
										showArrow
										showSearch
										// This is required for input focus on Select focused
										showAction={['focus', 'click']}
										optionFilterProp="children"
										id="priceType"
										{...field}
										onChange={(value: PriceType) => {
											form.setFieldValue(field.name as keyof PetFormValues, value, false);
											form.setFieldValue('ownerPhone', '');
											form.setFieldValue('ownerPhone2', '');

											if (value === PriceType.Retail) form.setFieldValue('clinicId', null);
										}}
									>
										<Select.Option value={PriceType.Wholesale}>{t('enums.price-type.wholesale')}</Select.Option>
										<Select.Option value={PriceType.Retail}>{t('enums.price-type.retail')}</Select.Option>
									</Select>
								)}
							/>
						)}
					</Field>
				</Col>
				<Field name="internalIdNum">
					{(fieldProps: FieldProps<PetFormValues>) => {
						const values = fieldProps.form.values;
						if (values.priceType !== PriceType.Retail) return null;
						const serviceTypePreference = getServiceTypePreferenceKind(values.serviceType);
						if (!crematory?.[serviceTypePreference]?.showInternalId) return null;

						return (
							<Col xs={24} sm={24} md={8}>
								<FormikInput
									fieldProps={fieldProps}
									title={<ControlLabel text={t(iKey('labels.clinic-internal-number'))} required={crematory[serviceTypePreference]?.requireInternalId} />}
									containerClassName=""
								/>
							</Col>
						);
					}}
				</Field>
			</Row>
			<Row gutter={[30, 8]}>
				<Col xs={24} sm={24} md={8}>
					<Field name="rush">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t(iKey('labels.rush'))}
								containerClassName=""
								render={({ field, form }: FieldProps<boolean, PetFormValues>) => (
									<Checkbox
										checked={field.value}
										onChange={(event: CheckboxChangeEvent) =>
											form.setFieldValue(field.name as keyof PetFormValues, event.target.checked, false)}
									>
										{t(iKey('labels.rush-details'))}
									</Checkbox>
								)}
							/>
						)}
					</Field>
				</Col>
				<Col xs={24} sm={24} md={8}>
					<Field name="onHold">
						{(fieldProps: FieldProps<PetFormValues>) => (
							<FormikInput
								fieldProps={fieldProps}
								title={t(iKey('labels.on-hold'))}
								containerClassName=""
								render={({ field, form }: FieldProps<PetOnHold, PetFormValues>) => (
									<Checkbox
										checked={field.value !== PetOnHold.None}
										onChange={(event: CheckboxChangeEvent) => {
											const value = event.target.checked ? PetOnHold.ByCrematory : PetOnHold.None;
											form.setFieldValue(field.name as keyof PetFormValues, value, false);
										}}
									>
										{t(iKey('labels.on-hold-details'))}
									</Checkbox>
								)}
							/>
						)}
					</Field>
				</Col>
			</Row>
		</Space>
	);
};
